import { A } from '@solidjs/router';

export function Confidenciality() {
  return (
    <p class="mt-4 text-xs text-gray-500">
      Informations personnelles recueillies pour le traitement de votre demande
      par TBM (exploité par Keolis Bordeaux Métropole Mobilités pour le compte
      de Bordeaux Métropole), conservées sur la base du contrat de transport ou
      de votre consentement, ou encore de l’intérêt légitime de TBM. Les données
      sont destinées à TBM, ses prestataires, aux personnes et organismes
      habilités à recevoir ces données. Les informations obligatoires sont
      mentionnées par un astérisque (sans les fournir votre demande ne pourra
      aboutir). Vous disposez d’un droit d’accès, de modification, de
      rectification, de limitation, d’opposition, de suppression des données
      vous concernant auprès de TBM, de réclamation auprès de la CNIL. Tous les
      détails du traitement de vos données personnelles et de vos droits sont
      disponibles à la rubrique « Politique de confidentialité » en pied de page
      du site{' '}
      <A href="http://infotbm.com/" class="text-primary underline">
        Transports Bordeaux Métropole - TBM
      </A>{' '}
      ou{' '}
      <A
        href="https://www.infotbm.com/fr/politique-de-confidentialite.html"
        class="text-primary underline"
      >
        ici
      </A>
      .
    </p>
  );
}
