import { createClient } from '@/lib/sdk';
import { defineStore } from '@/utils/defineStore';

export const [SdkProvider, useSdk] = defineStore({
  state: () => {
    const client = createClient(import.meta.env.VITE_API_URL);

    return client;
  },
});
