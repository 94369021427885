import {createEffect, createSignal, For, ParentProps, Show} from "solid-js";
import Input from "@/components/input";
import Select from "@/components/select";
import {$fetch} from "ofetch";
import {search} from 'solid-heroicons/outline';
import {Icon} from "solid-heroicons";
import {CustomerAddress} from "@/lib/sdk";

const COUNTRIES = {
  FR: 'France',
  ES: 'Espagne',
  EN: 'Angleterre',
  IT: 'Italie',
  OTHER: 'Autre',
};

export default function Address(props: ParentProps<{
  defaultAddress?: CustomerAddress | false;
  countries?: Record<string, string>;
}>,) {

  const [banoSearch, setBanoSearch] = createSignal('');
  const [isForeigner, setIsForeigner] = createSignal(false);
  const [showResult, setShowResult] = createSignal(false);
  const [result, setResult] = createSignal([]);
  const [lock, setLock] = createSignal(false);
  const [address, setAddress] = createSignal({
	streetNumber: props.defaultAddress ? props.defaultAddress.streetNumber : '',
	streetName: props.defaultAddress ? props.defaultAddress.streetName : '',
	postalCode: props.defaultAddress ? props.defaultAddress.postalCode : '',
	city: props.defaultAddress ? props.defaultAddress.city : '',
	country: props.defaultAddress ? props.defaultAddress.country : '',
	deliveryPoint: props.defaultAddress ? props.defaultAddress.deliveryPoint : '',
	deliveryPointSupplement: props.defaultAddress ? props.defaultAddress.deliveryPointSupplement : '',
	postalBox: props.defaultAddress ? props.defaultAddress.postalBox : '',
  });

  createEffect(
	  () => {
		setAddress({
		  streetNumber: props.defaultAddress ? props.defaultAddress.streetNumber : '',
		  streetName: props.defaultAddress ? props.defaultAddress.streetName : '',
		  postalCode: props.defaultAddress ? props.defaultAddress.postalCode : '',
		  city: props.defaultAddress ? props.defaultAddress.city : '',
		  country: props.defaultAddress ? props.defaultAddress.country : '',
		  deliveryPoint: props.defaultAddress ? props.defaultAddress.deliveryPoint : '',
		  deliveryPointSupplement: props.defaultAddress ? props.defaultAddress.deliveryPointSupplement : '',
		  postalBox: props.defaultAddress ? props.defaultAddress.postalBox : '',
		})
	  }
  );

  async function BANO(params: {
	q: string,
	limit?: number,
	lat?: number,
	lon?: number
  }) {
	return $fetch(import.meta.env.VITE_GOUVERNEMENT_API_URL, {params});
  }

  async function BANOSearch(search) {
	if (search.length < 3 || (search.length === 3 && /\s/g.test(search))) {
	  setResult([]);
	  setLock(false);
	  return false;
	}
	const result = await BANO({q: search, lat: 44.833608751816136, lon: -0.5982210989799939})
	setResult(result.features)
  }

  function selectedAddress(selectedAddress) {
	setAddress({
	  streetNumber: selectedAddress.housenumber,
	  streetName: selectedAddress.street,
	  postalCode: selectedAddress.postcode,
	  city: selectedAddress.city,
	  country: 'FR',
	  deliveryPoint: '',
	  deliveryPointSupplement: '',
	  postalBox: '',
	})
	setResult([])
	setLock(true)

	setBanoSearch(selectedAddress.label)
  }

  return (
	  <>
		<fieldset class="mt-8 space-y-4">
		  <legend class="font-semibold text-lg">
			Adresse postale
		  </legend>
		  <label class="relative inline-flex cursor-pointer items-center">
			<input type="checkbox" value="" onClick={() => {
			  setIsForeigner(!isForeigner())
			  setAddress({
				...address(),
				country: 'FR'
			  })
			  setLock(false)

			}} checked={isForeigner()}
				   class="peer sr-only"/>
			<div
				class="flex items-center gap-10 rounded-full border border-gray-400 px-8 h-12 after:content-[''] after:absolute after:bg-primary after:w-[55%] after:h-12 after:rounded-full after:left-0 after:transition-all peer-checked:after:left-[40%] peer-checked:after:w-[60%] peer-focus:outline-none text-gray-400"
			>
			  <span class={`relative z-[2] ${!isForeigner() ? 'text-white' : ''}`}>France</span>
			  <span class={`relative z-[2] ${isForeigner() ? 'text-white' : ''}`}>Etranger</span>
			</div>
		  </label>
		</fieldset>

		<fieldset class="mt-8 space-y-8">
		  <legend class="font-semibold text-lg">
			Saissisez votre adresse postale
		  </legend>

		  <Show when={!isForeigner()}>
			<div class="relative">
			  <Icon path={search} class="h-6 absolute left-0"/>
			  <Input onFocusIn={[setShowResult, true]} onFocusOut={[setShowResult, false]} class="pl-4"
					 onInput={async (e) => {
					   await BANOSearch(e.currentTarget.value);
					 }}
					 value={banoSearch()}
					 placeholder="Chercher une adresse"
			  />

			  <div
				  class={`${result().length > 0 && showResult() ? "visible opacity-100" : "invisible opacity-0"} transition-opacity absolute mt-1 z-10 w-full bg-white border border-primary rounded-b-lg shadow-lg`}>
				<For each={result()}>
				  {({properties}) => (
					  <div
						  class="p-2 border-b border-gray-200 transition-all hover:bg-primary hover:text-white cursor-pointer"
						  onClick={() => {
							selectedAddress(properties)
						  }}>
						<p class="text-sm">{properties.label}</p>
						<p class="text-xs">{properties.context}</p>
					  </div>
				  )}
				</For>
			  </div>
			</div>
		  </Show>


		  <div class="flex flex-col gap-4 space-y-4">
			<div class="col-span-3 col-start-1 row-start-1 flex space-x-4">
			  <Input
				  label="N° de voie"
				  placeholder="N° de voie"
				  class="w-28"
				  readOnly={lock()}
				  maxlength={6}
				  name="streetNumber"
				  value={address().streetNumber ?? ""}
			  />
			  <Input
				  label="Nom de la voie"
				  placeholder="Nom de la voie"
				  class="flex-1"
				  readOnly={lock()}
				  required
				  name="streetName"
				  value={address().streetName}
			  />
			</div>
			<div class="col-span-3 col-start-1 row-start-1 flex space-x-4">

			  <Input
				  label="Code postal"
				  placeholder="Code postal"
				  required
				  class="w-28"
				  readOnly={lock()}
				  maxlength={10}
				  name="postalCode"
				  value={address().postalCode}
			  />
			  <Input label="Ville" placeholder="Ville" required name="city"
					 class="flex-1"
					 readOnly={lock()}
					 value={address().city}
			  />
			</div>
			<Select
				label="Pays"
				placeholder="Pays"
				name="country"
				class={`${isForeigner() ? "" : "hidden"}`}
				required={isForeigner()}
				value={address().country ?? "FR"}
				options={Object.entries(props.countries ?? COUNTRIES).map(([value, label]) => ({
				  value,
				  label,
				}))}
			/>
		  </div>
		</fieldset>
		<fieldset class="mt-8 space-y-8">
		  <legend class="text-lg font-semibold">
			Ajouter des précisions à votre adresse
		  </legend>
		  <div class="grid grid-cols-2 gap-x-5 gap-y-8">
			<Input label="N° appart/escalier/étage" placeholder="N° appart/escalier/étage" name="deliveryPoint"
				   value={address().deliveryPoint ?? ""}
			/>
			<Input
				label="Bat/residence/immeuble"
				placeholder="Bat/residence/immeuble"
				name="deliveryPointSupplement"
				value={address().deliveryPointSupplement ?? ""}
			/>
			<Input label="Lieu dit/Boite postale" placeholder="Lieu dit/Boite postale" name="postalBox"
				   value={address().postalBox ?? ''}
			/>
		  </div>
		</fieldset>
	  </>
  )
}