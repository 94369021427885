// import footer3 from '@/assets/footer/sav.svg';
import footer1 from '@/assets/footer/delivery.svg';
import footer4 from '@/assets/footer/demat.svg';
import footer2 from '@/assets/footer/safe.svg';
import footer3 from '@/assets/footer/sav2.svg';
import { A } from '@solidjs/router';

export default function Footer() {
  return (
    <>
      <footer class="bg-[#065d81] px-4 py-12 font-semibold text-white">
        <div class="mx-auto max-w-7xl">
          <ul class="grid grid-cols-2 items-start gap-4 text-center lg:grid-cols-4">
            <li>
              <a
                class="flex flex-col items-center justify-center space-y-2 text-sm uppercase"
                href="https://aide.infotbm.com/?question=est-delai-livraison-mon-abonnement"
              >
                <img
                  src={footer1}
                  alt="Visuel indiquant que la livraison est gratuite"
                  height="48"
                  width="48"
                  class="block h-12 w-auto"
                />

                <p>Livraison gratuite</p>
              </a>
            </li>

            <li>
              <a
                class="flex flex-col items-center justify-center space-y-2 text-sm uppercase"
                href="https://aide.infotbm.com/?question=quels-modes-de-paiement-pour-payer-ma-commande-en-ligne"
              >
                <img
                  src={footer2}
                  alt="Visuel indiquant que le paiement est sécurisé"
                  height="48"
                  width="48"
                  class="block h-12 w-auto"
                />

                <p>Paiement sécurisé</p>
              </a>
            </li>

            <li>
              <a
                class="flex flex-col items-center justify-center space-y-2 text-sm uppercase"
                href="https://www.infotbm.com/fr/contactez-nous"
              >
                <img
                  src={footer3}
                  alt="Visuel indiquant que le service est disponible 5 jours sur 7"
                  height="48"
                  width="48"
                  class="block h-12 w-auto"
                />

                <p>Service client 5 jours sur 7</p>
              </a>
            </li>

            <li>
              <a
                class="flex flex-col items-center justify-center space-y-2 text-sm uppercase"
                href="https://aide.infotbm.com/?question=peut-on-avoir-ticket-mobile"
              >
                <img
                  src={footer4}
                  alt="Visuel indiquant que les titres sont disponbile en version dématerialisés"
                  height="48"
                  width="48"
                  class="block h-12 w-auto"
                />

                <p>M-ticket 100% dematérialisé</p>
              </a>
            </li>
          </ul>

          <hr class="mx-auto my-6 max-w-sm border-gray-50 border-opacity-20 lg:my-10" />

          <div class="justify-around lg:flex">
            <section class="flex items-center space-x-3 lg:space-x-6">
              <p>Suivons-nous !</p>

              <ul class="flex items-center space-x-3 lg:space-x-4">
                <li>
                  <a
                    href="https://twitter.com/info_TBM"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">Twitter</span>
                    <img
                      class="block h-8 w-auto text-white duration-200 hover:scale-110"
                      height="32"
                      width="32"
                      src="/footer2/twitter.svg"
                      alt="Logo Twitter"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/InfoTBM"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">Facebook</span>
                    <img
                      class="block h-8 w-auto text-white duration-200 hover:scale-110"
                      src="/footer2/facebook.svg"
                      height="32"
                      width="32"
                      alt="Logo Facebook"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UChrce1aX6gPrPVn9c_P3SyQ"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">Youtube</span>
                    <img
                      class="block h-8 w-auto duration-200 hover:scale-110"
                      src="/footer2/youtube.svg"
                      height="32"
                      width="32"
                      alt="Logo Youtube"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/infotbm/"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">Instagram</span>
                    <img
                      class="block h-8 w-auto duration-200 hover:scale-110"
                      src="/footer2/instagram.svg"
                      height="32"
                      width="32"
                      alt="Logo Instagram"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@tbm_officiel"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">TikTok</span>
                    <img
                      class="block h-8 w-auto duration-200 hover:scale-110"
                      src="/footer2/tiktok.svg"
                      height="32"
                      width="32"
                      alt="Logo TikTok"
                    />
                  </a>
                </li>
              </ul>
            </section>

            <hr class="mx-auto my-6 max-w-sm border-gray-50 border-opacity-20 lg:hidden" />

            <section class="flex items-center space-x-3 lg:space-x-6">
              <p class="whitespace-nowrap">Découvrir l'app</p>

              <ul class="flex space-x-4">
                <li>
                  <a
                    href="https://apps.apple.com/fr/app/tbm-tram-bus-bat3-v3-p-r/id1492096341"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">App Store</span>
                    <img
                      class="block h-10 w-auto lg:h-12"
                      src="/footer2/apple.svg"
                      height="48"
                      width="142"
                      alt="Disponible sur Apple Store"
                    />
                  </a>
                </li>

                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.infotbm.tbm&gl=FR"
                    target="_blank"
                    rel="noopener"
                  >
                    <span class="sr-only">Disponible sur Google Play</span>
                    <img
                      class="block h-10 w-auto lg:h-12"
                      src="/footer2/android.svg"
                      height="48"
                      width="142"
                      alt="Disponible sur Google Play"
                    />
                  </a>
                </li>
              </ul>
            </section>
          </div>

          <hr class="mx-auto my-6 max-w-sm border-gray-50 border-opacity-20 lg:my-8" />

          <nav class="text-xs lg:text-inherit">
            <ul class="flex flex-wrap items-center justify-center lg:flex-row lg:justify-center lg:space-x-2 lg:space-y-0">
              <li class="px-2">
                <a
                  href="https://aide.infotbm.com/?_ga=2.235464897.1189880809.1659949494-490954393.1659949494"
                  target="_blank"
                  rel="noopener"
                >
                  Aide {'&'} Contact
                </a>
              </li>
              <li class="px-2">
                <a
                  href="https://www.infotbm.com/fr/conditions-generales-de-vente-et-mentions-legales.html"
                  target="_blank"
                  rel="noopener"
                >
                  CGV et mentions légales
                </a>
              </li>
              <li class="px-2">
                <a
                  href="https://www.infotbm.com/fr/politique-de-confidentialite.html"
                  target="_blank"
                  rel="noopener"
                >
                  Politique de confidentialité
                </a>
              </li>
              <li class="px-2">
                <a
                  href="https://www.infotbm.com/fr/politique-de-gestion-des-cookies.html"
                  target="_blank"
                  rel="noopener"
                >
                  Gestion des cookies
                </a>
              </li>
              <li class="px-2">
                <A href="/sitemap">Plan du site</A>
              </li>

              <li class="px-2">
                <A
                  href="https://www.infotbm.com/fr/declaration-daccessibilite-boutique-tbm.html"
                  target="_blank"
                  rel="noopener"
                >
                  Accessibilité numérique : non conforme
                </A>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </>
  );
}
