import 'keen-slider/keen-slider.min.css';

import {
  ParentProps,
  mergeProps,
  onCleanup,
  splitProps,
  createSignal,
  createEffect,
} from 'solid-js';

import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';

import { Arrow } from './arrow';

function useKeen(options: KeenSliderOptions) {
  const [container, setContainer] = createSignal<HTMLElement>();
  const [slider, setSlider] = createSignal<KeenSliderInstance>();

  createEffect(() => {
    const slider = new KeenSlider(container(), options);
    setSlider(slider);
  });

  onCleanup(() => {
    slider()?.destroy();
  });

  return [setContainer, slider] as const;
}

interface CarouselProps extends KeenSliderOptions {
  class?: string;
  as?: string;
  arrow?: boolean;
}

export default function Carousel(props: ParentProps<CarouselProps>) {
  const finalProps = mergeProps(
    {
      class: '',
    },
    props,
  );

  const [currentSlide, setCurrentSlide] = createSignal(0);
  const [internal, options] = splitProps(finalProps, ['children', 'class']);
  const [ref, instance] = useKeen({
    ...options,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  const isFirstSlide = () => currentSlide() === 0;
  const isLastSlide = () => {
    return currentSlide() === instance()?.track?.details?.slides.length - 1;
  };

  return (
    <div class="relative">
      <div ref={ref} class={`keen-slider relative ${internal.class}`}>
        {internal.children}
      </div>

      <Arrow
        direction="previous"
        onClick={(event) => {
          event.stopPropagation();
          instance()?.prev();
        }}
        disabled={isFirstSlide()}
      />
      <Arrow
        direction="next"
        onClick={(event) => {
          event.stopPropagation();
          instance()?.next();
        }}
        disabled={isLastSlide()}
      />
    </div>
  );
}
