import { JSX, ParentProps } from 'solid-js';

interface Props extends JSX.HTMLAttributes<HTMLDivElement> {}

export default function Loader(props: ParentProps<Props>) {
  return (
    <svg
      style="color: currentColor"
      width="64"
      height="64"
      viewBox="0 0 16 16"
      fill="none"
      class={`animate-spin ${props.class}`}
    >
      <circle
        cx="8"
        cy="8"
        r="7"
        stroke="currentColor"
        stroke-opacity="0.25"
        stroke-width="2"
        vector-effect="non-scaling-stroke"
      ></circle>
      <path
        d="M15 8a7.002 7.002 0 00-7-7"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        vector-effect="non-scaling-stroke"
      ></path>
    </svg>
  );
}
