/**
 * Generate a unique ID based on time
 * Is it SSR friendly though?
 * @link https://gist.github.com/gordonbrander/2230317?permalink_comment_id=3970386#gistcomment-3970386
 * @returns {string} - Unique ID
 */
export function createUniqueId(): string {
  return new Date()
    .getTime()
    .toString(36)
    .concat(performance.now().toString(), Math.random().toString())
    .replace(/\./g, '');
}
