import {createSignal, Show} from "solid-js";
import {formatDate} from "@/utils/formatDate";
import Button from "@/components/button";
import {useAuth} from "@/stores/auth";
import {useSdk} from "@/stores/sdk";
import {SvdPairing} from "@/lib/sdk";
import {extractFormValues} from "@/pages/associate/passenger";
import {getInitials} from "@/pages/account";


export default function Found(props: {
  svdCheckInfo: SvdPairing,
  onSubmit?: (userId: string, isMainAccount: boolean, svdCheckInfo: SvdPairing | null) => void,
  isMainAccount?: boolean,
  tryptiqueForm?: any,
  passengerRcuId?: string,
}) {
  const [auth, authActions] = useAuth();
  const [sdk] = useSdk();

  const [loading, setLoading] = createSignal(false);
  const [error, setError] = createSignal('');
  const [code, setCode] = createSignal(['', '', '', '', '', '']);
  const [localSvdCheckInfo, setLocalSvdCheckInfo] = createSignal<SvdPairing>(props.svdCheckInfo);
  const [pairingId, setPairingId] = createSignal('');
  const [passengerId, setPassenger] = createSignal(props.passengerRcuId ?? auth.user.rcuId);

  const fullName = (user = null) => {
	if (!user) {
	  return `${auth.user.firstName} ${auth.user.lastName}`;
	}

	return `${user.firstName} ${user.lastName}`;
  };
  const initials = () => props.isMainAccount ? getInitials(fullName()) : getInitials(fullName(props.tryptiqueForm));


  const handleKeyUp = (index) => (e) => {
	const value = e.target.value;
	if (value.length === 1) {
	  if (index < 5) {
		document.getElementById(`code-${index + 2}`).focus();
	  }
	} else if (index > 0) {
	  document.getElementById(`code-${index}`).focus();
	}
	setCode(code.map((c, i) => (i === index ? value : c)));
  };

  async function sendMail() {
	setLoading(true);
	try {
	  if (props.isMainAccount) {
		const {pairingId} = await sdk.svd.sendPairingEmail();
		setPairingId(pairingId);
		//props.onSubmit(auth.user.thalesCustomerId, props.svdCheckInfo);
		setLocalSvdCheckInfo({...props.svdCheckInfo, message: 'EMAIL_SENT'});
	  } else {
		await sdk.customer.associationValidation(props.tryptiqueForm);
		setLocalSvdCheckInfo({...props.svdCheckInfo, message: 'AWAITING_VALIDATION'});
		props.onSubmit(auth.user.rcuId, props.isMainAccount, localSvdCheckInfo());
	  }


	} catch (e) {
	  setError('Erreur lors de l\'envoi de l\'e-mail');
	} finally {
	  setLoading(false);
	}
  }

  async function handleCode(e) {
	e.preventDefault();
	setLoading(true);
	const target = e.target as HTMLFormElement;

	const form = extractFormValues(
		target,
		['code-1', 'code-2', 'code-3', 'code-4', 'code-5', 'code-6', 'code-7', 'fallback-code'],
	);

	const code = Object.values(form).join('');

	if (code.length < 1) {
	  setError('Le code est incomplet');
	  setLoading(false);
	  return;
	}

	let rcuId = '';

	const isMainAccount = passengerId() === auth.user.rcuId


	try {
	  if (!props.isMainAccount) {
		await sdk.customer.associationValidation({
		  ...props.tryptiqueForm,
		  code
		});
		const childrens = await sdk.customer.children(false);

		const userAssociate = childrens.find((child) => {
		  const childBirthDate = new Date(child.birthDate)
		  const birthDate = new Date(props.tryptiqueForm.birthDate)
		  return child.firstName.toUpperCase() === props.tryptiqueForm.firstName.toUpperCase() && child.lastName.toUpperCase() === props.tryptiqueForm.lastName.toUpperCase() && childBirthDate.getTime() === birthDate.getTime()
		})

		rcuId = userAssociate.rcuId;

	  } else if (localSvdCheckInfo().message === 'EMAIL_SENT') {

		if (isMainAccount) {
		  await sdk.svd.submitEmailCodePairing(code, pairingId());
		  rcuId = auth.user.rcuId
		} else {
		  await sdk.svd.submitEmailCodePairing(code, pairingId(), passengerId());
		  rcuId = passengerId()
		}

	  } else {

		if (isMainAccount) {
		  await sdk.svd.submitCodePairing(code);
		  rcuId = auth.user.rcuId
		} else {
		  await sdk.svd.submitCodePairing(code, passengerId());
		  rcuId = passengerId()
		}

	  }

	  const user = await sdk.auth.customer(auth.token);


	  authActions.set({user});
	  props.onSubmit(rcuId);
	} catch (error) {
	  console.error(error?.cause?._data?.error);
	  if (error?.cause?._data?.message === 'PAIRING_ERROR') {
		setError('Le code renseignée ne correspond pas au voyageur')
	  } else {
		setError('Une erreur est survenue, veuillez réessayer.')
	  }
	} finally {
	  setLoading(false);
	}
  }

  const handlePaste = (e) => {
	e.preventDefault();
	const pastedData = e.clipboardData.getData('text');
	if (pastedData.length === 6 && !isNaN(pastedData)) {
	  setCode(pastedData.split(''));
	}
  };

  return (
	  <><Show when={localSvdCheckInfo().canPairByCode}
			  fallback={
				<section class="flex flex-col items-center gap-10">
				  <p class="text-center">
					<Show when={props.isMainAccount} fallback={
					  <>
						Pour l'ajouter à votre comptre TBM, le voyageur doit donner son accord.
						Un e-mail sera envoyé à l'adresse {localSvdCheckInfo().email}.
						Une fois l'autorisation donnée par le voyageur, vous pourrez poursuivre votre commande.
					  </>
					}>
					  <>
						Confirmez qu’il s’agit bien de vous en renseignant le code à 6 chiffres envoyé par e-mail à
						l’adresse {localSvdCheckInfo().pairingEmail}. Une fois cette confirmation réalisée, vous pourrez
						poursuivre
						votre commande.
					  </>
					</Show>
				  </p>

				  <article
					  class="flex h-full flex-col items-center justify-center rounded-lg border-2 p-6 text-center bg-gray-50 peer-checked:border-primary"
				  >
					<div
						class="flex h-14 w-14 items-center justify-center overflow-hidden rounded-full bg-primary text-white">
					  <span class="text-xl">{initials()}</span>
					</div>

					<Show when={props.isMainAccount} fallback={
					  <>
				<span class="mt-6 font-semibold">
			  		{props.tryptiqueForm.firstName} {props.tryptiqueForm.lastName}
				</span>

						<span class="mt-2 text-sm text-gray-500">
			  		né(e) le {formatDate(props.tryptiqueForm.birthDate)}
		  		</span>
					  </>
					}>
					  <>
				<span class="mt-6 font-semibold">
			  		{auth.user.firstName} {auth.user.lastName}
				</span>

						<span class="mt-2 text-sm text-gray-500">
			  		né(e) le {formatDate(auth.user.birthDate)}
				</span>
					  </>
					</Show>
				  </article>
				  <Show when={error()}>
					<p class="text-center my-4 whitespace-pre-wrap rounded-lg border border-red-700 bg-red-50 p-4 text-sm font-semibold text-red-700">
					  {error()}
					</p>
				  </Show>
				  <Button loading={loading()} onClick={sendMail} variant="secondary">
					Envoyer la demande
				  </Button>
				</section>
			  }>
		<section class="flex flex-col items-center gap-10">
		  <Show when={localSvdCheckInfo().message === 'EMAIL_SENT'}
				fallback={
				  <>
					<Show when={props.isMainAccount} fallback={
					  <>
						<p class="text-center">
						  Saisissez le code client
						  de {props.tryptiqueForm.firstName} {props.tryptiqueForm.lastName} pour poursuivre
						  votre commande.
						</p>
						<p>
						  Le code client est le numéro inscrit sur la carte TBM.
						</p>
					  </>
					}>
					  <>
						<p class="text-center">
						  Saisissez votre code client pour confirmer qu’il s’agit bien de vous.
						</p>
						<p>
						  Votre code client est le numéro inscrit sur votre carte TBM.
						</p>
					  </>
					</Show>

				  </>
				}>
			<p class="text-center">
			  Saisissez le code à 6 chiffres réceptionné par e-mail pour poursuivre votre commande.
			</p>
		  </Show>
		  <form onSubmit={handleCode} class="max-w-sm mx-auto">
			<Show when={localSvdCheckInfo().message === 'EMAIL_SENT'}
				  fallback={
					<>
					  <input
						  type="text"
						  name="fallback-code"
						  id="fallback-code"
						  class="block w-full h-14 py-3 bg-gray-100 text-sm font-extrabold text-center text-gray-900 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
					  />
					</>
				  }>
			  <div class="flex justify-center mb-2 space-x-2 rtl:space-x-reverse">
				{code().map((c, index) => (
					<div>
					  <label for={`code-${index + 1}`} class="sr-only">Code {index + 1}</label>
					  <input
						  type="text"
						  maxLength="1"
						  name={`code-${index + 1}`}
						  id={`code-${index + 1}`}
						  class="block w-11 h-14 py-3 bg-gray-100 text-sm font-extrabold text-center text-gray-900 border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
						  value={c}
						  onKeyUp={handleKeyUp(index)}
						  onPaste={handlePaste}
					  />
					</div>
				))}
			  </div>
			</Show>
			<div class="flex flex-col justify-center mt-6">
			  <Show when={error()}>
				<p class="text-center my-4 whitespace-pre-wrap rounded-lg border border-red-700 bg-red-50 p-4 text-sm font-semibold text-red-700">
				  {error()}
				</p>
			  </Show>
			  <Button loading={loading()} type="submit" variant="secondary">
				Valider le code
			  </Button>
			</div>
		  </form>

		</section>

	  </Show>
	  </>
  )
}