import { JSX, ParentProps, mergeProps, splitProps } from 'solid-js';

import { createUniqueId } from '@/utils/createUniqueId';

interface Props extends JSX.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  hint?: string;
  inline?: boolean;
  loading?: boolean;
}

export default function Checkbox(props: ParentProps<Props>) {
  const id = createUniqueId();

  const mergedProps = mergeProps({ id }, props);
  const [internal, external] = splitProps(mergedProps, [
    'label',
    'class',
    'inline',
  ]);

  return (
    <div
      class={`flex space-y-2 ${internal.inline ? 'flex-row' : 'flex-col'} ${
        internal.class
      }`}
    >
      <label
        for={external.id}
        class={`mr-auto flex items-center space-x-2 ${
          external.disabled || external.readOnly
            ? 'cursor-disabled opacity-50'
            : 'cursor-pointer'
        }`}
      >
        <input type="checkbox" {...external} />

        <span>{internal.label}</span>
      </label>
    </div>
  );
}
