import '@/assets/css/bordelBordeaux.css';
import { A } from '@solidjs/router';

export default function TopBar() {
  return (
    <div
      id="bb"
      class="tbm-header !hidden !font-sans font-[500] lg:!flex"
      style="width: 100vw; z-index: 2;"
    >
      <div class="tbm-top bg-elm c-snow display-md-block">
        <div class="px-9">
          <div class="tbm-top-nav row justify-space-between align-content-stretch align-md-center m-0">
            <div>
              <nav class="tbm-nav fs-small">
                <ul class="list display-flex align-md-center align-content-stretch textransform-uppercase my-0.5">
                  <li>
                    <a
                      href="https://www.infotbm.com"
                      title="Le réseau TBM"
                      class="mx-6"
                    >
                      Le réseau TBM
                    </a>
                  </li>
                  <li class="is-active bg-snow display-none display-md-block font-semibold">
                    <A href="/" class="is-active mx-5" title="E-boutique">
                      Boutique
                    </A>
                  </li>
                  <li>
                    <a
                      href="http://tbmfid.infotbm.com/"
                      target="_blank"
                      title="Ecomobi"
                      class="mx-6"
                    >
                      TBM fid'
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              <nav class="tbm-nav tbm-nav-icon fs-small">
                <ul class="list display-flex align-md-center textransform-uppercase">
                  <li>
                    <a
                      href="https://aide.infotbm.com"
                      title="Aide et contact"
                      class="mx-6"
                    >
                      <svg class="f-snow mr-2.5  h-4 w-5 scale-[1.2]">
                        <path
                          fill-rule="nonzero"
                          d="M7.893 0a8 8 0 1 0 .215 16 8 8 0 0 0-.215-16zM7.87 12.87l-.044-.001c-.68-.02-1.16-.521-1.14-1.192.019-.659.51-1.137 1.168-1.137h.04c.699.022 1.173.518 1.153 1.207-.02.661-.503 1.123-1.177 1.123zm2.862-5.68c-.16.227-.512.51-.955.855l-.488.337c-.268.208-.43.404-.49.597-.048.152-.072.192-.076.5v.08H6.856l.006-.158c.022-.649.038-1.03.307-1.345.422-.495 1.352-1.094 1.391-1.12.133-.1.246-.214.33-.336.195-.27.282-.482.282-.69 0-.29-.086-.558-.256-.797-.164-.23-.474-.346-.923-.346-.445 0-.75.14-.932.43a1.73 1.73 0 0 0-.282.931v.08H4.858l.004-.083c.05-1.178.47-2.025 1.248-2.52.49-.315 1.098-.475 1.808-.475.93 0 1.715.226 2.333.672.626.451.943 1.128.943 2.01a2.3 2.3 0 0 1-.463 1.378z"
                        ></path>
                      </svg>
                      <span>Aide et contact</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
