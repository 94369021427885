import { ParentProps, Show, mergeProps, JSX } from 'solid-js';
import { Portal } from 'solid-js/web';

interface Props {
  open: boolean;
  transparent?: boolean;
  zIndex?: number;
  onClick?: JSX.EventHandlerUnion<HTMLDivElement, MouseEvent>;
}

export default function Overlay(props: ParentProps<Props>) {
  const mergedProps = mergeProps({ transparent: false, zIndex: 40 }, props);

  const zIndex = () => {
    switch (mergedProps.zIndex) {
      case 10:
        return 'z-10';
      case 20:
        return 'z-20';
      case 30:
        return 'z-30';
      case 40:
        return 'z-40';
      case 50:
      default:
        return 'z-50';
    }
  };

  return (
    <Show when={props.open}>
      <Portal mount={document.getElementById('overlay')}>
        <div
          class={`fixed inset-0 h-screen w-screen ${
            mergedProps.transparent ? 'bg-transparent' : 'bg-black'
          } opacity-70 ${zIndex()}`}
          onClick={props?.onClick}
        ></div>
      </Portal>
    </Show>
  );
}
