export function pick<T extends Record<string, unknown>, U extends (keyof T)[]>(
  obj: T,
  ...keys: U
) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) => keys.includes(key) && Boolean(value),
    ),
  );
}
