import { createUniqueId } from '@/utils/createUniqueId';
import { Icon } from 'solid-heroicons';
import { check, eye, eyeOff, trash } from 'solid-heroicons/solid';
import {
  JSX,
  ParentProps,
  Show,
  createEffect,
  createSignal,
  mergeProps,
  splitProps,
} from 'solid-js';
import Loader from './loader';

interface Props extends JSX.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hint?: string;
  inline?: boolean;
  loading?: boolean;
  onClear?: (input: HTMLInputElement) => void;
  error?: string;
  showSuccess?: boolean;
}

export default function Input(props: ParentProps<Props>) {
  const id = createUniqueId();
  let inputRef: HTMLInputElement;

  const mergedProps = mergeProps({ id, type: 'text' }, props);
  const [internal, external] = splitProps(mergedProps, [
    'children',
    'label',
    'class',
    'hint',
    'inline',
    'type',
    'loading',
    'onClear',
    'showSuccess',
    'error',
  ]);

  const [internalType, setInternalType] = createSignal(internal.type);

  createEffect(() => {
    setInternalType(internal.type);
  });

  return (
    <div
      class={`flex space-y-2 ${internal.inline ? 'flex-row' : 'flex-col'} ${
        internal.class
      }`}
    >
      <Show when={internal.label}>
        <div class="flex items-center justify-between">
          <label
            for={external.id}
            class="mr-auto cursor-pointer text-sm font-semibold text-primary"
          >
            {internal.label}
            {external.required && '*'}
          </label>

          <Show when={internal.type === 'password'}>
            <button
              type="button"
              onClick={() =>
                setInternalType((type) =>
                  type === 'text' ? 'password' : 'text',
                )
              }
            >
              <Icon
                class="h-6"
                path={internalType() === 'text' ? eyeOff : eye}
              />
            </button>
          </Show>
        </div>
      </Show>

      <Show when={internal.children}>
        <p class="whitespace-pre-wrap text-sm text-gray-700">
          {props.children}
        </p>
      </Show>

      <div class="relative">
        <input
          ref={inputRef}
          disabled={internal.loading}
          type={internalType()}
          class="w-full border-b bg-transparent px-3 pb-2 pt-0 text-sm read-only:opacity-50 read-only:cursor-not-allowed focus:border-primary focus:outline-none disabled:opacity-50"
          classList={{
            'border-red-700': !!internal.error,
            'border-green-700': internal.showSuccess,
            'border-gray-300': !internal.error && !internal.showSuccess,
            'pr-10': internal.loading,
          }}
          {...external}
        />

        <div class="absolute right-0 top-1/2 flex -translate-y-1/2 items-center gap-2">
          <Show when={internal.showSuccess && !internal.error}>
            <Icon class="h-6  text-green-700 opacity-80" path={check} />
          </Show>

          <Show when={internal.type === 'file' && internal.onClear}>
            <button
              type="button"
              class="text-red-700 opacity-80"
              onClick={[internal.onClear, inputRef]}
            >
              <Icon class="h-6" path={trash} />
            </button>
          </Show>

          <Show when={internal.loading}>
            <Loader class="h-6" />
          </Show>
        </div>
      </div>

      <Show when={internal.hint}>
        <p class="whitespace-pre-wrap text-xs text-gray-600">{internal.hint}</p>
      </Show>

      <Show when={internal.error}>
        <p class="mt-1 whitespace-pre-wrap text-xs text-red-700">
          {internal.error}
        </p>
      </Show>
    </div>
  );
}
