import { Icon } from 'solid-heroicons';
import { ParentProps, JSX, splitProps } from 'solid-js';
import { chevronLeft, chevronRight } from 'solid-heroicons/solid';

interface Props extends JSX.ButtonHTMLAttributes<HTMLButtonElement> {
  direction: 'previous' | 'next';
}

export function Arrow(props: ParentProps<Props>) {
  const [internal, external] = splitProps(props, ['direction']);

  return (
    <button
      classList={{
        'left-0 -translate-x-1/3': internal.direction === 'previous',
        'right-0 translate-x-1/3': internal.direction === 'next',
      }}
      class="absolute top-1/2 flex h-10 w-10 -translate-y-1/2 items-center justify-center rounded-full bg-primary text-white disabled:cursor-not-allowed disabled:opacity-50"
      {...external}
    >
      <span class="sr-only">
        {internal.direction === 'previous' ? 'Précédant' : 'Suivant'}
      </span>
      <Icon
        class="h-6 w-6"
        path={internal.direction === 'previous' ? chevronLeft : chevronRight}
      />
    </button>
  );
}
