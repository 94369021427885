import { createEffect, createSignal, ParentProps } from 'solid-js';
import { arrow, computePosition, flip, offset, shift } from '@floating-ui/dom';
import { Icon } from 'solid-heroicons';

// This tooltip component is a port of the component linked below tailored to my needs
// https://tallpad.com/series/vuejs-misc/lessons/building-a-vue-tooltip-component-using-tailwindcss-and-floating-ui
export function Tooltip(
  props: ParentProps<{
    title: string;
    icon: ParentProps['children'];
    placement: 'top' | 'bottom' | 'left' | 'right';
  }>,
) {
  let arrowRef: HTMLDivElement;
  let floatingRef: HTMLDivElement;
  let referenceRef: HTMLButtonElement;

  const [isHidden, setIsHidden] = createSignal(true);

  async function calculatePosition() {
    const { x, y, middlewareData, placement } = await computePosition(
      referenceRef,
      floatingRef,
      {
        placement: props.placement,
        middleware: [
          offset(8),
          flip(),
          shift({ padding: 5 }),
          arrow({ element: arrowRef }),
        ],
      },
    );

    Object.assign(floatingRef.style, {
      left: `${x}px`,
      top: `${y}px`,
    });

    const { x: arrowX, y: arrowY } = middlewareData.arrow;

    const opposedSide = {
      left: 'right',
      right: 'left',
      bottom: 'top',
      top: 'bottom',
    }[placement.split('-')[0]];

    Object.assign(arrowRef.style, {
      left: arrowX ? `${arrowX}px` : '',
      top: arrowY ? `${arrowY}px` : '',
      bottom: '',
      right: '',
      [opposedSide]: '-4px',
    });
  }

  createEffect(() => {
    if (!isHidden()) {
      calculatePosition();
    }
  });

  return (
    <>
      <button
        type="button"
        ref={referenceRef}
        onBlur={[setIsHidden, true]}
        onFocus={[setIsHidden, false]}
        onMouseEnter={[setIsHidden, false]}
        onMouseLeave={[setIsHidden, true]}
        onClick={[setIsHidden, false]}
        class="block"
      >
        <span class="sr-only">{props.title}</span>
        {props.icon}
      </button>

      <div
        ref={floatingRef}
        class="absolute top-0 left-0 z-50 cursor-default overflow-hidden rounded-md bg-[#065D81] p-4 text-sm text-white"
        classList={{
          hidden: isHidden(),
        }}
      >
        {props.children}

        <div ref={arrowRef} class="absolute h-[8px] w-[8px] bg-[#065D81]"></div>
      </div>
    </>
  );
}
