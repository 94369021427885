import { format } from 'date-fns';

/**
 * Returns a locale formatted date string.
 * @param date {string} - Valid valid date for Date constructor
 * @returns {string} - Formatted date
 */
export function formatDate(
  date: string | Date | number,
  options?: {
    format?: 'INPUT' | 'ISO';
    fallback?: string;
    locale?: string;
  },
): string {
  try {
    const dateObject = new Date(date);

    return options?.format === 'INPUT'
      ? format(dateObject, 'yyyy-MM-dd')
      : dateObject.toLocaleDateString();
  } catch {
    return options?.fallback ?? String(date);
  }
}
