import { JSX, ParentProps } from 'solid-js';

export default function IconCart(
  props: ParentProps<JSX.SvgSVGAttributes<SVGSVGElement>>,
) {
  return (
    <svg viewBox="0 0 23 31" {...props}>
      <path
        d="M21.4 6.3H16V5a4.5 4.5 0 1 0-9 0v1.3H1.6a.7.7 0 0 0-.6.6v19.5c0 2.2 1.8 4.1 4 4.1h13c2.2 0 4-1.9 4-4.1V6.9c0-.3-.3-.6-.6-.6zM8.3 5c0-1.7 1.4-3.2 3.2-3.2s3.2 1.5 3.2 3.2v1.3H8.3V5zM7 7.6v3.6c0 .3.3.6.6.6a.7.7 0 0 0 .7-.6V7.6h6.4v3.6c0 .3.3.6.7.6a.7.7 0 0 0 .6-.6V7.6h4.7v14.3H2.3V7.6H7zm11 21.6H5a2.8 2.8 0 0 1-2.7-2.8v-3.2h18.4v3.2a2.8 2.8 0 0 1-2.8 2.8z"
        fill="#333"
        stroke="#333"
        stroke-width=".3"
      />
    </svg>
  );
}
