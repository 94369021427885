import Overlay from './overlay';
import logo from '@/assets/logo.svg';
import IconCart from '@/icons/cart';
import IconUser from '@/icons/user';
import {CatalogListResponse} from '@/lib/sdk';
import {useGlobalStore} from '@/stores';
import {useAuth} from '@/stores/auth';
import {cleanProductTitle} from '@/utils/cleanProductTitle';
import {A, useLocation, useNavigate} from '@solidjs/router';
import {Icon} from 'solid-heroicons';
import {chevronDown, chevronRight, menu} from 'solid-heroicons/solid';
import {createEffect, createSignal, For, on, onCleanup, ParentProps, Show,} from 'solid-js';

export default function Header(
	props: ParentProps<{ catalog: CatalogListResponse[] }>,
) {
  const [auth, authActions] = useAuth();
  const go = useNavigate();
  const location = useLocation();
  const [store, actions] = useGlobalStore();

  function openCart() {
	const url = new URL(window.location.href);
	url.searchParams.set('cart-open', 'true');

	let path = url.pathname;

	if (url.search) {
	  path += url.search;
	}

	if (url.hash) {
	  path += url.hash;
	}

	go(path);
  }

  const [activeTab, setActiveTab] = createSignal(-1);

  createEffect(
	  on(
		  () => location.pathname,
		  () => {
			setActiveTab(-1);
		  },
	  ),
  );

  return (
	  <>
		<header
			class="relative z-40 flex items-center justify-between border-b bg-white py-[7.5px] pr-4 text-sm lg:px-10 lg:pb-[10.4px] lg:pt-5">
		  <div class="flex">
			<button
				type="button"
				class="px-4 lg:hidden"
				onClick={actions.toggleMenu}
			>
			  <span class="sr-only">Ouvrir le menu</span>
			  <Icon path={menu} class="h-6 w-auto text-primary"/>
			</button>

			<A
				href="/"
				class="absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2  lg:relative lg:left-auto lg:top-auto lg:translate-x-0 lg:translate-y-0 lg:px-0"
			>
			  <span class="sr-only">Aller sur la page d'accueil</span>
			  <img
				  src={logo}
				  alt="Logo TBM"
				  height="64"
				  class="h-11 lg:h-[69px] "
			  />
			</A>

			<ul class="ml-8 hidden flex-1 items-center  text-gray-700 lg:flex">
			  <For each={props.catalog}>
				{(category, index) => {
				  let el: HTMLLIElement;

				  const handleClickOutside = (event: MouseEvent) => {
					if (activeTab() === -1) return;
				  };

				  document.addEventListener('click', handleClickOutside);

				  onCleanup(() => {
					document.removeEventListener('click', handleClickOutside);
				  });

				  return (
					  <li
						  ref={el}
						  class="relative z-20 flex h-full flex-1 items-center whitespace-nowrap after:absolute after:bottom-0 after:left-0 after:z-30 after:h-6 after:w-full after:-translate-y-1/2 after:bg-white"
						  classList={{
							'after:hidden': activeTab() !== index(),
							'after:block': activeTab() === index(),
						  }}
					  >
						<button
							type="button"
							onClick={() =>
								setActiveTab(index() === activeTab() ? -1 : index())
							}
							class="z-40 flex flex-1 items-center space-x-2 whitespace-nowrap rounded-xl   px-4 py-3 hover:text-primary"
							classList={{
							  'text-primary shadow-kbm': activeTab() === index(),
							}}
						>
						  <span>{category.name}</span>
						  <Icon
							  path={chevronDown}
							  class="h-4 text-primary"
							  classList={{'rotate-180': activeTab() === index()}}
						  />
						</button>

						<div
							class="absolute left-0 top-full z-20 flex min-w-[300px] -translate-y-4 rounded-xl  bg-white p-4 shadow-kbm"
							classList={{
							  flex: activeTab() === index(),
							  hidden: activeTab() !== index(),
							}}
						>
						  {/* HACK: This is a hack to handle click away and close the menu */}
						  <Overlay
							  open={activeTab() === index()}
							  transparent
							  zIndex={10}
							  onClick={() => setActiveTab(-1)}
						  />

						  <div class="flex flex-col space-y-6">
							<Show when={category.products.length > 0}>
							  <ul class="flex flex-col space-y-4">
								<For each={category.products}>
								  {(product) => (
									  <li>
										<A
											href={`/products/${product.id}`}
											class="flex items-center space-x-2 hover:text-primary"
										>
										  <Icon
											  path={chevronRight}
											  class="h-4 text-primary"
										  />
										  <span>
                                      {cleanProductTitle(product.title)}
                                    </span>
										</A>
									  </li>
								  )}
								</For>
							  </ul>
							</Show>

							<For each={category.children}>
							  {(subcategory) => (
								  <div class="space-y-4">
									<h2 class="font-semibold text-primary">
									  {subcategory.name}
									</h2>

									<ul class="flex flex-col space-y-4">
									  <For each={subcategory.products}>
										{(product) => (
											<li>
											  <A
												  href={`/products/${product.id}`}
												  class="flex items-center space-x-2 hover:text-primary"
											  >
												<Icon
													path={chevronRight}
													class="h-4 text-primary"
												/>
												<span>
                                          {cleanProductTitle(product.title)}
                                        </span>
											  </A>
											</li>
										)}
									  </For>
									</ul>
								  </div>
							  )}
							</For>
						  </div>
						</div>
					  </li>
				  );
				}}
			  </For>
			</ul>
		  </div>

		  <Show when={store.closureStatus?.closed !== true}>
			<div class="flex items-center">
			  <div class="items-center space-x-4 p-2 lg:flex">
				<Show when={auth.isAuthenticated}
					  fallback={
						<div
							onClick={() => authActions.login()}
							class="relative after:absolute after:left-full cursor-pointer after:top-0 after:h-2 after:w-2 after:-translate-y-1/2 after:rounded-full"
							classList={{
							  'after:bg-green-600': auth.isAuthenticated,
							  'after:bg-red-600': !auth.isAuthenticated,
							}}
						>
						  <IconUser
							  class="h-6"
							  classList={{'text-primary': auth.isAuthenticated}}
						  />
						</div>
					  }>
				  <A
					  href={`/account`}
					  class="relative after:absolute after:left-full cursor-pointer after:top-0 after:h-2 after:w-2 after:-translate-y-1/2 after:rounded-full"
					  classList={{
						'after:bg-green-600': auth.isAuthenticated,
						'after:bg-red-600': !auth.isAuthenticated,
					  }}
				  >
					<IconUser
						class="h-6"
						classList={{'text-primary': auth.isAuthenticated}}
					/>
				  </A>
				</Show>

				<p class="hidden flex-col lg:flex">
				  <Show when={auth.isAuthenticated} fallback={
					<div
						onClick={() => authActions.login()}
						class="hover:underline cursor-pointer"
					>
                        <span class="sr-only">
                        Accéder à{' '}
						  La page de connexion
                        </span>
					  Me connecter
					</div>
				  }>
					<A
						href="/account"
						class="hover:underline"
					>
                    <span class="sr-only">
                      Accéder à{' '}
					  'Mon compte TBM '
                    </span>
					  {auth.user.kbmCustomerName}
					</A>
				  </Show>
				</p>
			  </div>

			  <Show when={auth.isAuthenticated}>
				<button onClick={openCart} type="button" class="relative p-2">
				  <span class="sr-only">Accéder au panier</span>
				  <IconCart class="h-8"/>

				  <Show when={actions.cartNumber()}>
                  <span
					  class="absolute bottom-0 right-0 h-4 w-4 rounded-full bg-red-500 text-xs font-semibold text-white">
                    {actions.cartNumber()}
                  </span>
				  </Show>
				</button>
			  </Show>
			</div>
		  </Show>
		</header>

		<Show when={store.closureStatus?.closed}>
		  <div class="bg-red-100 px-6 py-4 text-red-800">
			<p class="text-center">{store.closureStatus?.message}</p>
		  </div>
		</Show>
	  </>
  );
}
