import { ParentProps, createEffect, createSignal, Show } from 'solid-js';

export default function Counter(
  props: ParentProps<{
    min?: number;
    max?: number;
    value?: number | string;
    name?: string;
    by?: number;
    onValueChange?: (value: number) => unknown;
    class?: string;
    readOnly?: boolean;
  }>,
) {
  const [count, setCount] = createSignal(1);

  createEffect(() => {
    if (props.value !== undefined) {
      setCount(+props.value);
    }
  });

  createEffect(() => {
    if (!props.onValueChange || props.readOnly) {
      return;
    }

    props.onValueChange(count());
  });

  return (
    <>
      <input
        type="number"
        value={count()}
        name={props.name}
        min={props.min}
        max={props.max}
        class="sr-only"
      />
      <div class={`flex items-center space-x-4 ${props.class}`}>
        <Show when={!props.readOnly}>
          <button
            type="button"
            disabled={props.min && props.value <= props.min}
            class="inline-flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 text-xl text-primary disabled:cursor-not-allowed disabled:opacity-70"
            onClick={() => setCount((c) => c - 1)}
          >
            -
          </button>
        </Show>
        <span class="font-semibold">{count()}</span>
        <Show when={!props.readOnly}>
          <button
            type="button"
            disabled={props.min && props.value >= props.max}
            class="inline-flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 text-xl text-primary disabled:cursor-not-allowed disabled:opacity-70"
            onClick={() => setCount((c) => c + 1)}
          >
            +
          </button>
        </Show>
      </div>
    </>
  );
}
