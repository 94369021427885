import {
  createContext,
  createEffect,
  on,
  useContext,
  ParentProps,
} from 'solid-js';
import { SetStoreFunction, createStore, Store } from 'solid-js/store';

export function extractValue<T>(jsonLike: string, fallback?: T) {
  try {
    return JSON.parse(jsonLike);
  } catch {
    return fallback || jsonLike;
  }
}

/**
 * This merge multiple object together just like {...ob1, ...obj2 },
 * but will also merge the getters and setters.
 *
 * @param sources - The different object to merge
 * @returns {T} The merged object
 */
export function merge<T>(...sources: T[]): T {
  const result: Record<string, unknown> = {};

  for (const source of sources) {
    const props = Object.keys(source);
    for (const prop of props) {
      const descriptor = Object.getOwnPropertyDescriptor(source, prop);
      Object.defineProperty(result, prop, descriptor);
    }
  }
  return result as T;
}

/**
 * Primitive that synchronize a store with the localStorage.
 * Essentially, just a Solid store with side effects
 *
 * @param key {string} - The key in the localStorage
 * @param value {T} - The initial value if it's not already in localStorage
 * @param [properties] {string[]} - Optional array of properties to store
 * @returns - A Solid Store
 */
export function createLocalStorage<T>(
  key: string,
  value: T,
  properties?: string[],
) {
  const valueFromStorage = localStorage.getItem(key);
  // TODO: Check that the value from the store has the same structure that
  // the one passed in the value somehow, we could use `zod` for that

  const initialValue = valueFromStorage
    ? // We have to merge the value from storage with the initial value,
      // so that we can get the getters (memo) of the state...
      merge(value, extractValue(valueFromStorage))
    : value;

  // @ts-expect-error : typing issue
  const [state, setState] = createStore<T>(initialValue);

  createEffect(() => {
    const json = JSON.parse(JSON.stringify(state));

    // TODO: Find a better way later
    if (properties && typeof json === 'object') {
      for (const key of Object.keys(json)) {
        if (!properties.includes(key)) {
          delete json[key];
        }
      }
    }

    localStorage.setItem(key, JSON.stringify(json));
  });

  return [state, setState] as const;
}

/**
 * Helper function to create stores with a common interface
 */
export function defineStore<T extends Record<string, any>, U>(storeDefinition: {
  state: () => T;
  actions?: (state: Store<T>, set: SetStoreFunction<T>) => U;
  watchers?: () => Record<T[string], (state: any) => void>;
  plugins?: ((state: Store<T>, set: SetStoreFunction<T>) => void)[];
  storage?: {
    key: string;
    properties?: string[];
  };
}) {
  function createStoreContext() {
    const initialState = storeDefinition.state();

    const [state, setState] = storeDefinition.storage
      ? createLocalStorage<T>(
          storeDefinition.storage.key,
          initialState,
          storeDefinition.storage.properties,
        )
      : createStore<T>(initialState);

    const actions = {
      ...(storeDefinition.actions?.(state, setState) ?? {}),
      set: setState,
      reset: () => setState(initialState),
    } as unknown as { set: SetStoreFunction<T>; reset: () => void } & U;

    if (storeDefinition.watchers) {
      const watchers = Object.entries(storeDefinition.watchers());

      for (const [property, watcher] of watchers) {
        createEffect(on(() => state[property], watcher as any));
      }
    }

    return [state, actions] as const;
  }

  // Compute the type of the store dynamically and inject it into the context
  // creation function
  type StoreContextState = ReturnType<typeof createStoreContext>;
  const StoreContext = createContext<StoreContextState>();

  const Provider = (props: ParentProps) => {
    const storeContext = createStoreContext();

    return (
      <StoreContext.Provider value={storeContext}>
        {props.children}
      </StoreContext.Provider>
    );
  };

  const useProvider = () => useContext(StoreContext)!;

  return [Provider, useProvider] as const;
}
