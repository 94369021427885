import { Icon } from 'solid-heroicons';
import { chevronDown, chevronRight } from 'solid-heroicons/solid';
import { For, ParentProps, Show, Suspense } from 'solid-js';

import { CatalogListResponse, ChildCategory } from '@/lib/sdk';
import { cleanProductTitle } from '@/utils/cleanProductTitle';
import { A } from '@solidjs/router';

function MenuSubCategory(props: ParentProps<ChildCategory>) {
  return (
    <div>
      <h2 class="font-semibold text-primary">{props.name}</h2>

      <ul class="mt-2 flex flex-col space-y-2">
        <For each={props.products}>
          {(product) => (
            <li>
              <A
                href={`/products/${product.id}`}
                class="flex items-center space-x-4 hover:underline"
              >
                <Icon path={chevronRight} class="h-6 text-primary" />
                <span>{cleanProductTitle(product.title)}</span>
              </A>
            </li>
          )}
        </For>
      </ul>
    </div>
  );
}

function MenuCategory(
  props: ParentProps<{ category: CatalogListResponse; open?: boolean }>,
) {
  return (
    <details class="group" open={props.open}>
      <summary class="flex cursor-pointer list-none items-center justify-between p-4 marker:hidden group-open:border-b group-open:font-semibold group-open:text-primary">
        <span>{props.category.name}</span>
        <Icon
          path={chevronDown}
          class="h-6 text-primary transition-transform duration-300 group-open:rotate-180"
        />
      </summary>

      <Show when={props.category.products.length}>
        <ul class="mt-2 flex flex-col space-y-2 p-4">
          <For each={props.category.products}>
            {(product) => (
              <li>
                <A
                  href={`/products/${product.id}`}
                  class="flex items-center space-x-2 hover:underline"
                >
                  <Icon path={chevronRight} class="h-6 text-primary" />
                  <span>{cleanProductTitle(product.title)}</span>
                </A>
              </li>
            )}
          </For>
        </ul>
      </Show>

      <Show when={props.category.children?.length}>
        <div class="flex flex-col space-y-8 p-4">
          <For each={props.category.children}>
            {(subcateogory) => <MenuSubCategory {...subcateogory} />}
          </For>
        </div>
      </Show>
    </details>
  );
}

export default function Menu(
  props: ParentProps<{ open: boolean; catalog: CatalogListResponse[] }>,
) {
  return (
    <Show when={props.open}>
      <aside class="fixed inset-0 top-[57px] z-30 divide-y overflow-auto bg-white lg:top-auto lg:hidden lg:pt-[73px]">
        <Suspense>
          <For each={props.catalog}>
            {(category) => <MenuCategory category={category} />}
          </For>
        </Suspense>

        <ul class="flex flex-col divide-y">
          <li>
            <A
              href="#"
              class="block bg-primary p-4 text-xs font-semibold uppercase text-white"
            >
              Les autres rubriques
            </A>
          </li>
          <li>
            <a
              href="https://www.infotbm.com/fr"
              class="block bg-primary p-4 text-xs font-semibold uppercase text-white"
            >
              Le réseau TBM
            </a>
          </li>
          <li>
            <a
              href="https://ecomobi.fr/"
              rel="noopener"
              target="_blank"
              class="block bg-primary p-4 text-xs font-semibold uppercase text-white"
            >
              Ecomobi
            </a>
          </li>
          <li>
            <a
              href="https://aide.infotbm.com/"
              rel="noopener"
              target="_blank"
              class="block bg-primary p-4 text-xs font-semibold uppercase text-white"
            >
              Aide et contact
            </a>
          </li>
        </ul>
      </aside>
    </Show>
  );
}
