import { JSX, ParentProps } from 'solid-js';

export default function IconAlert(
  props: ParentProps<JSX.SvgSVGAttributes<SVGSVGElement>>,
) {
  return (
    <svg {...props} viewBox="0 0 30 30">
      <circle
        cx="15"
        cy="15"
        r="15"
        fill="#e2007a"
        opacity="0.1"
        style="isolation: isolate"
      />
      <path d="M15,5A10,10,0,1,0,25,15,10,10,0,0,0,15,5Z" fill="#e2007a" />
      <path
        d="M15,18.33a1.43,1.43,0,0,1,0,2.86,1.43,1.43,0,0,1,0-2.86Zm1.43-7.5a31.11,31.11,0,0,1-1,6c-.18.7-.73.71-.91,0a30.31,30.31,0,0,1-1-6C13.57,8.14,16.43,8.13,16.43,10.83Z"
        fill="#fff"
      />
    </svg>
  );
}
