import { JSX, ParentProps, splitProps } from 'solid-js';

interface CarouselSlideProps
  extends ParentProps<JSX.HTMLAttributes<HTMLDivElement>> {}

export default function CarouselSlide(props: CarouselSlideProps) {
  const [internal, external] = splitProps(props, ['children']);

  return (
    <div class="keen-slider__slide" {...external}>
      {internal.children}
    </div>
  );
}
