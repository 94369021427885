interface FormatPriceConfig {
  asString?: true | false;
  locale?: string;
}

/**
 * This function takes a price in cents and converts it into a float to reprent
 * the regular price in euros. If the config is set to return a string, the
 * price will be returned formatted with the EURO sign.
 *
 * @param price {number|string} - The price to format
 * @param config {FormatPriceConfig} - The config object
 * @returns {number} - The formatted price
 */
export function formatPrice(
  price: number | string,
  config?: { asString?: true; locale?: string },
): string;
export function formatPrice(
  price: number | string,
  config?: { asString?: false; locale?: string },
): number;
export function formatPrice(
  price: number | string,
  config: FormatPriceConfig = {
    asString: true,
    locale: 'fr-FR',
  },
): string | number {
  try {
    const finalPrice =
      typeof price === 'string' ? Number.parseInt(price, 10) : price;

    const priceAsFloat = finalPrice / 100;

    if (config.asString) {
      return priceAsFloat.toLocaleString(config.locale, {
        currency: 'EUR',
        style: 'currency',
      });
    }

    return priceAsFloat;
  } catch {
    return price;
  }
}
