import { Portal } from 'solid-js/web';
import { Icon } from 'solid-heroicons';
import { x } from 'solid-heroicons/outline';
import { ParentProps, Show, JSX, onCleanup, mergeProps } from 'solid-js';

import Overlay from './overlay';

export default function Modal(
  props: ParentProps<{
    title?: string;
    open: boolean;
    class?: string;
    onClose?: JSX.EventHandlerUnion<
      HTMLDivElement | HTMLButtonElement,
      MouseEvent
    >;
    zIndex?: number;
  }>,
) {
  const mergedProps = mergeProps({ zIndex: 50, class: '' }, props);

  const zIndex = () => {
    switch (mergedProps.zIndex) {
      case 10:
        return 'z-10';
      case 20:
        return 'z-20';
      case 30:
        return 'z-30';
      case 40:
        return 'z-40';

      case 60:
        return 'z-[60]';
      case 50:
      default:
        return 'z-50';
    }
  };

  // Liste to escape key press and send the props.onClose event if exists
  function handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape' && props.onClose) {
      // To understand this bit, you need to read the documentation about
      // the different kind of way Solid handles events. One of this is
      // via a tuple.
      // TODO: There's a world where this `props.onClose` in NOT a tuple.
      // We need to account for that case.
      if (Array.isArray(props.onClose)) {
        const [onClose, value] = props.onClose;
        onClose(value);
      }
    }
  }

  window.addEventListener('keydown', handleKeyDown);

  onCleanup(() => {
    window.removeEventListener('keydown', handleKeyDown);
  });

  return (
    <>
      <Overlay
        open={props.open}
        onClick={props?.onClose}
        zIndex={mergedProps.zIndex - 10}
      />

      <Show when={props.open}>
        <Portal mount={document.getElementById('modal')!}>
          <div
            class={`fixed inset-0 flex items-center overflow-hidden p-0 lg:p-20 ${zIndex()}`}
          >
            <div
              class={`${mergedProps.class} overlow-hidden relative mx-auto flex max-h-full w-full max-w-7xl flex-col rounded-lg bg-white p-6 pt-12 lg:w-auto lg:min-w-[50rem]`}
            >
              <Show when={props.title}>
                <header>
                  <h1 class="text-center text-xl font-semibold text-primary">
                    {props.title}
                  </h1>
                </header>
              </Show>

              <Show when={props.onClose}>
                <button
                  type="button"
                  onClick={props.onClose}
                  class="absolute top-6 right-6"
                >
                  <span class="sr-only">Fermer le document</span>
                  <Icon path={x} class="h-8" />
                </button>
              </Show>

              <main class="flex-1 overflow-auto">
                <div class="mx-auto w-full max-w-xl py-6">{props.children}</div>
              </main>
            </div>
          </div>
        </Portal>
      </Show>
    </>
  );
}
