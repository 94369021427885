import {useAuth} from './stores/auth';
import {useSdk} from './stores/sdk';
import Cart from '@/components/cart';
import Footer from '@/components/footer';
import Header from '@/components/header';
import Menu from '@/components/menu';
import TopBar from '@/components/topBar';
import Home from '@/pages/index';
import {useGlobalStore} from '@/stores';
import {CookieOptions, cookieStorage} from '@solid-primitives/storage';
import {Route, Routes, useLocation, useNavigate, useSearchParams,} from '@solidjs/router';
import {add} from 'date-fns';
import {Component, createEffect, createResource, createSignal, lazy, on, onError, onMount, Show,} from 'solid-js';
import {toast, Toaster} from 'solid-toast';
import Button from "@/components/button";

// TODO: Movie this out of the file
const [extension, domain] = window.location.hostname.split('.').reverse();
const DOMAIN = `.${domain}.${extension}`;

export const COOKIE_PARAMS: CookieOptions = import.meta.env.PROD
	? {sameSite: 'Lax', secure: true, path: '/', domain: DOMAIN}
	: {sameSite: 'Lax', secure: true, path: '/'};

const SiteMap = lazy(() => import('@/pages/sitemap'));
const SearchResult = lazy(() => import('@/pages/search'));
const ProductCategory = lazy(() => import('@/pages/categories/[id]'));

const AccountLayout = lazy(() => import('@/pages/account'));
const AccountIndex = lazy(() => import('@/pages/account/index'));
const AccountDetails = lazy(() => import('@/pages/account/[id]'));
const Personal = lazy(() => import('@/pages/account/personal'));
const NewPassenger = lazy(() => import('@/pages/account/new'));
const History = lazy(() => import('@/pages/account/history'));
const HistoryDetails = lazy(() => import('@/pages/account/history/[id]'));
const Fine = lazy(() => import('@/pages/account/fine'));
const AccountUnpaids = lazy(() => import('@/pages/account/unpaids'));

const ProductDetails = lazy(() => import('@/pages/products/[id]'));

const Association = lazy(() => import('@/pages/associate/index'));
const AssociationCard = lazy(() => import('@/pages/associate/card'));
const AssociationData = lazy(() => import('@/pages/associate/data'));
const AssociationTerms = lazy(() => import('@/pages/associate/terms'));
const AssociationPassenger = lazy(() => import('@/pages/associate/passenger'));

const CartIndex = lazy(() => import('@/pages/cart/index'));
const CartDelivery = lazy(() => import('@/pages/cart/delivery'));
const CartMethod = lazy(() => import('@/pages/cart/method'));
const CartCheckout = lazy(() => import('@/pages/cart/checkout'));
const CartConfirmation = lazy(() => import('@/pages/cart/confirmation'));

const App: Component = () => {
  const [sdk] = useSdk();
  const go = useNavigate();
  const location = useLocation();
  const [auth, authActions] = useAuth();
  const [searchParams] = useSearchParams();
  const [globalState, globalActions] = useGlobalStore();


  const [catalog] = createResource(sdk.catalog.list);

  // Store the catalog globally so that it can be reused elsewhere
  createEffect(() => {
	if (catalog.loading) {
	  return;
	}

	type Category = {
	  id: number;
	  name: string;
	  parent?: Category;
	  smartContextTag?: string;
	};

	const categories = catalog().reduce<Category[]>((categories, entry) => {
	  const rootCategory: Category = {
		id: entry.id,
		name: entry.name,
		smartContextTag: entry.smartContextTag,
	  };

	  const subCategories = entry.children.map<Category>((subCategory) => {
		return {
		  id: subCategory.id,
		  name: subCategory.name,
		  parent: rootCategory,
		  smartContextTag: subCategory.smartContextTag,
		};
	  });

	  return [...categories, rootCategory, ...subCategories];
	}, []);

	globalActions.set({categories});
	globalActions.set({catalog: catalog()});
  });

  // Add a callback that logout the user if the session
  // is expired
  sdk.onAuthExpired(async (_error) => {
	if (!localStorage.getItem('eshop-bd-authToken')) {
	  go('/');
	}
  });

  const [loadingSession, setLoadingSession] = createSignal(true);

  onMount(async () => {
	try {
	  await authActions.checkLogged();
	} finally {
	  setLoadingSession(false);
	}
  });

  // Handle GA cookie
  onMount(async () => {
	try {
	  const GA_COOKIE_NAME = 'kst-5e3e8cdd8348f08fdf0fae3c306f8231';

	  const GA_COOKIE_PARAMS: CookieOptions = import.meta.env.PROD
		  ? {sameSite: 'Lax', secure: true, path: '/', domain: DOMAIN}
		  : {sameSite: 'Lax', secure: true, path: '/'};

	  const GACookie = cookieStorage.getItem(GA_COOKIE_NAME, GA_COOKIE_PARAMS);

	  if (!GACookie) {
		const ID = crypto.randomUUID();
		const encryptedID = await crypto.subtle.digest(
			'SHA-256',
			new TextEncoder().encode(ID),
		);

		const hashArray = Array.from(new Uint8Array(encryptedID)); // convert buffer to byte array

		const hashHex = hashArray
			.map((b) => b.toString(16).padStart(2, '0'))
			.join(''); // convert bytes to hex string

		const parsed = 'kstcssuid=' + hashHex;

		GA_COOKIE_PARAMS.expires = add(new Date(), {months: 3});

		cookieStorage.setItem(GA_COOKIE_NAME, parsed, GA_COOKIE_PARAMS);
	  } else {
		GA_COOKIE_PARAMS.expires = add(new Date(), {months: 3});

		cookieStorage.setItem(GA_COOKIE_NAME, GACookie, GA_COOKIE_PARAMS);
	  }
	} catch (error) {
	  console.log('Could not set GA cookie', error);
	}
  });

  function addGtagScript() {
	if (!document.querySelector('script[src="/gtag.js"]')) {
	  const script = document.createElement("script");

	  script.src = "/gtag.js";
	  script.async = true;

	  document.body.appendChild(script);
	}
  }

  const authLog = (...params: any[]) => {
	if (import.meta.env.VITE_DEBUG_MODE) {
	  console.log('[DEBUG AUTH]', ...params);
	}
  };

  sdk.addCallback(async (options, headers) => {

	try {
	  authLog({tokenExpiration: auth.tokenExpiration});

	  if (!auth.tokenExpiration) {
		addGtagScript()
		return [options, headers];
	  }

	  let now = Date.now();
	  let accessTokenExpiration = parseInt(auth.tokenExpiration, 10);

	  authLog({now, accessTokenExpiration}, accessTokenExpiration > now);

	  if (accessTokenExpiration > now) {
		headers.set('authorization', `Bearer ${auth.token}`);
		addGtagScript()
		return [options, headers];
	  }

	  authLog({refreshToken: auth.refreshToken});

	  if (!auth.refreshToken) {
		addGtagScript()
		return [options, headers];
	  }

	  const response = await authActions.refresh();

	  authLog({refreshTokenResponse: response});

	  now = Date.now();
	  accessTokenExpiration = now + (response.expires_in * 1000);

	  authActions.set({
		token: response.access_token,
		tokenExpiration: accessTokenExpiration.toString(),
	  });

	  authLog(auth.token);

	  if (auth.token) {
		headers.set('authorization', `Bearer ${auth.token}`);
	  }
	  addGtagScript()
	  return [options, headers];
	} catch {
	  addGtagScript()
	  return [options, headers];
	}
  });

  // Listen to URL changes and close the menu if open
  createEffect(
	  on(
		  () => location.pathname,
		  async () => {
			// Close the sidebar if openb
			globalActions.set({menuOpen: false});

			// Handle cart clearing every 24h
			const today = new Date();
			today.setHours(4, 0, 0, 0);

			const lastCartDate = localStorage.getItem('lastCartDate');

			if (lastCartDate) {
			  const lastCart = new Date(lastCartDate);
			  lastCart.setHours(4, 0, 0, 0);

			  const shouldClearCart = lastCart < today;

			  if (shouldClearCart) {
				if (globalState.cart.length > 0) {
				  toast.error("Votre panier n'est plus valide");
				}

				globalActions.clearStore();
			  }
			}

			localStorage.setItem('lastCartDate', today.toString());
		  },
	  ),
  );

  onMount(async () => {
	//TODO store the closure status on cache to avoid multiple calls
	try {
	  const closureStatus = await sdk.closureStatus();

	  globalActions.set('closureStatus', closureStatus);
	} catch {
	}
  });

  // Catch error accross the whole app and log them into the console
  // This prevent the app from crashing on random javascript issues
  // TODO: Handle this in a better way
  onError((error) => {
	if (import.meta.env.DEV) {
	  console.error(error.message);
	  console.error(error.stack);
	}
  });

  return (
	  <>
		<TopBar/>
		<Header catalog={catalog()}/>

		<Menu open={globalState.menuOpen} catalog={catalog()}/>

		<Show when={searchParams['cart-open'] && auth.isAuthenticated}>
		  <Cart/>
		</Show>

		<Routes>
		  <Route path="/" element={<Home/>}/>
		  <Route path="/search" element={<SearchResult/>}/>
		  <Route path="/categories/:id" element={<ProductCategory/>}/>

		  <Route path="/account" element={<AccountLayout/>}>
			<Route path="/fine" element={<Fine/>}/>
			<Route path="/" element={<Personal/>}/>
			<Route path="/voyagers" element={<AccountIndex/>}/>
			<Route path="/voyagers/:id" element={<AccountDetails/>}/>
			<Route path="/new" element={<NewPassenger/>}/>
			<Route path="/history" element={<History/>}/>
			<Route path="/history/:id" element={<HistoryDetails/>}/>
			<Route path="/unpaids" element={<AccountUnpaids/>}/>
		  </Route>

		  <Button onClick={() => authActions.login()}/>

		  <Route path="/products/:id" element={<ProductDetails/>}/>
		  <Route path="/sitemap" element={<SiteMap/>}/>

		  <Route path="/associate" element={<Association/>}>
			<Route path="/passenger" element={<AssociationPassenger/>}/>
			<Route path="/card" element={<AssociationCard/>}/>
			<Route path="/data" element={<AssociationData/>}/>
			<Route path="/terms" element={<AssociationTerms/>}/>
		  </Route>

		  <Route path="/cart" element={<CartIndex/>}>
			<Route path="/delivery" element={<CartDelivery/>}/>
			<Route path="/methods" element={<CartMethod/>}/>
			<Route path="/data" element={<CartData/>}/>
			<Route path="/checkout" element={<CartCheckout/>}/>
			<Route path="/confirmation" element={<CartConfirmation/>}/>
		  </Route>
		</Routes>

		<Footer/>

		<Toaster position="bottom-right"/>
	  </>
  );
};

export default App;
