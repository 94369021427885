import { createUniqueId } from '@/utils/createUniqueId';
import { For, JSX, ParentProps, Show, mergeProps, splitProps } from 'solid-js';

interface Option extends JSX.OptionHTMLAttributes<HTMLOptionElement> {
  label: string;
}

interface Props extends JSX.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  hint?: string;
  options?: string[] | Option[];
  placeholder?: string;
  selectClass?: string;
}

export default function Select(props: ParentProps<Props>) {
  const id = createUniqueId();

  const mergedProps = mergeProps({ id, type: 'text', options: [] }, props);
  const [internal, external] = splitProps(mergedProps, [
    'children',
    'label',
    'class',
    'hint',
    'options',
    'selectClass',
    'value',
  ]);

  return (
    <div class={`flex flex-col space-y-2 ${internal.class}`}>
      <Show when={internal.label}>
        <label
          for={external.id}
          class="mr-auto cursor-pointer text-sm font-semibold text-primary"
        >
          {internal.label}
          {external.required && '*'}
        </label>
      </Show>

      <select
        {...external}
        class={`w-full border-b border-gray-300 bg-transparent px-3 pb-2 text-sm focus:border-primary focus:outline-none disabled:opacity-50  ${internal.selectClass}`}
      >
        <Show when={props.placeholder}>
          {/* the value="" is required so that the native form validation kicks in */}
          <option
            value=""
            disabled
            hidden
            selected={internal.value === undefined}
          >
            {props.placeholder}
          </option>
        </Show>

        <For each={internal.options} fallback={internal.children}>
          {(option) => {
            const isString = () => typeof option === 'string';

            return (
              <Show
                when={!isString()}
                fallback={
                  <option selected={internal.value === option}>{option}</option>
                }
              >
                <option
                  selected={internal.value === option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              </Show>
            );
          }}
        </For>
      </select>

      <Show when={internal.hint}>
        <p class="text-xs text-gray-600">{internal.hint}</p>
      </Show>
    </div>
  );
}
