import { JSX, ParentProps } from 'solid-js';

export default function IconUser(
  props: ParentProps<JSX.SvgSVGAttributes<SVGSVGElement>>,
) {
  return (
    <svg viewBox="0 0 20 21" {...props}>
      <path
        d="M13.5 12.8a6.6 6.6 0 0 0 3.2-5.6c0-3.7-3-6.7-6.7-6.7a6.7 6.7 0 0 0-3.5 12.3A10.3 10.3 0 0 0 0 20.5h1.7a8.5 8.5 0 0 1 16.6 0H20a10 10 0 0 0-6.5-7.7zM5 7.2c0-2.8 2.3-5 5-5s5 2.2 5 5-2.3 5-5 5-5-2.3-5-5z"
        fill="currentColor"
      />
    </svg>
  );
}
