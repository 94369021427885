import { Dynamic } from 'solid-js/web';
import { JSX, ParentProps, Show, mergeProps, splitProps } from 'solid-js';

import { omit } from '@/utils/omit';
import { A } from '@solidjs/router';

interface Props
  extends Record<string, any>,
    JSX.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'grey' | 'base';
  loading?: boolean;
  href?: string;
}

export default function Button(props: ParentProps<Props>) {
  const mergedProps = mergeProps({ type: 'button' }, props);

  const [internal, external] = splitProps(mergedProps, [
    'variant',
    'children',
    'class',
    'loading',
  ]);

  const variant = () => {
    switch (internal.variant) {
      case 'primary':
        return 'bg-primary text-white border-primary';
      case 'secondary':
        return 'bg-secondary text-white border-secondary';
      case 'grey':
        return 'bg-gray-300 border-gray-300 normal-case';
      case 'base':
      default:
        return 'bg-white text-gray-800 border-gray-400';
    }
  };

  const tag = () => (external.href ? A : 'button');
  const externalProps = () => {
    const isButton = tag() === 'button';
    if (isButton) return external;

    return omit(external, 'type');
  };

  return (
    <Dynamic
      component={tag()}
      class={`flex items-center justify-center space-x-2 rounded-full border px-6 py-3 text-sm font-semibold uppercase disabled:cursor-not-allowed disabled:saturate-0 ${variant()} ${
        internal.class
      }`}
      classList={{ 'cursor-progress': internal.loading }}
      disabled={tag() === 'button' ? internal.loading : undefined}
      {...externalProps()}
    >
      <Show when={internal.loading}>
        <svg class="h-4 w-4 animate-spin" fill="none" viewBox="0 0 24 24">
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </Show>

      <span>{internal.children}</span>
    </Dynamic>
  );
}
