import {
  ParentProps,
  Show,
  createEffect,
  createSignal,
  mergeProps,
  onCleanup,
} from 'solid-js';

const smartContextUrl =
  'https://assets.app.smart-tribune.com/smart-tribune/FAQContext/faqcontext.main.js';

const useSmartContext = () => {
  const [scriptIsLoaded, setScriptIsLoaded] = createSignal(false);

  createEffect(() => {
    const script = document.createElement('script');

    script.src = smartContextUrl;
    script.async = true;

    document.body.appendChild(script);

    script.onload = () => {
      setScriptIsLoaded(true);
    };

    onCleanup(() => {
      document.body.removeChild(script);
    });
  });

  return scriptIsLoaded;
};

interface Props {
  tags?: string[];
  iconName?: string;
  containerId?: string;
}

export function SmartContextContainer(props: ParentProps<Props>) {
  const scriptIsLoaded = useSmartContext();
  const finalProps = mergeProps({ containerId: 'st-faq-context' }, props);

  createEffect(() => {
    if (!scriptIsLoaded()) {
      return;
    }

    if (!finalProps.tags) {
      return;
    }

    window.addEventListener('STFAQContextLoaded', (event: CustomEvent) => {
      const faq = event.detail;
      const initialization = {
        kbId: 6,
        locale: 'fr',
        cookieOptin: false,
        containerId: finalProps.containerId,
        filters: {
          tags: finalProps.tags,
        },
        limit: 25,
      };

      faq.init(initialization);
    });
  });

  return (
    <Show when={finalProps.tags} fallback={<></>}>
      <div class="st-faq-context">
        <div id={finalProps.containerId}></div>
      </div>
    </Show>
  );
}

export const TAGS = {
  HOME: 'context-boutique-home-3636',

  "A l'unité": 'context-boutique-unite-3638',
  '10 voyages réduits': 'context-boutique-reduit-3639',

  'A la journée': 'context-boutique-journee-3640',
  'Pass Pitchoun 7 jours': 'context-boutique-hebdo-3641',
  'Pass Jeune 7 jours': 'context-boutique-hebdo-3641',
  'Le Pass 7 jours': 'context-boutique-hebdo-3641',
  'Pass Senior 7 jours': 'context-boutique-hebdo-3641',
  'City Pass 24 heures': 'context-boutique-citypass-3637',
  'City Pass 48 heures': 'context-boutique-citypass-3637',
  'City Pass 72 heures': 'context-boutique-citypass-3637',

  Mensuel: 'context-boutique-mensuel-3642',
  'Pass Pitchoun Mensuel Solidaire 2': 'context-boutique-solidaire-3643',
  'Pass Pitchoun Mensuel Solidaire 3': 'context-boutique-solidaire-3643',
  'Pass Jeune Mensuel Solidaire 2': 'context-boutique-solidaire-3643',
  'Pass Jeune Mensuel Solidaire 3': 'context-boutique-solidaire-3643',
  'Le Pass Mensuel Solidaire 2': 'context-boutique-solidaire-3643',
  'Le Pass Mensuel Solidaire 3': 'context-boutique-solidaire-3643',
  'Pass Senior Mensuel Solidaire 2': 'context-boutique-solidaire-3643',
  'Pass Senior Mensuel Solidaire 3': 'context-boutique-solidaire-3643',

  Annuel: 'context-boutique-annuel-3644',
  'Pass Salarié': 'context-boutique-salarie-3645',

  Vélo: 'context-boutique-v3-3646',
  'TBM + partenaires': 'context-boutique-partenaires-3647',
};
