export function cleanProductTitle(title: string): string {
  /**
   * This essentially handle the "Passe Salarié" product.
   * The format is as follows: "Passe Salarié -20%, Passe Salarié -30%"
   * So we remove the percentage from the title, split in the comma and only take the first part.
   * This ends up with "Passe Salarié"
   */
  const [cleanTitle] = title.replace(/ -\d+%/g, '').split(',');

  return cleanTitle;
}
