import Button from '@/components/button';
import Modal from '@/components/modal';
import { Product } from '@/lib/sdk';

interface Props {
  product: Product;
  option: number | null | undefined;
  onClose: () => void;
  onSubmit: () => void;
}

export function OptionInfoModal(props: Props) {
  const option = () => {
    for (const group of props.product.allowedOptionGroups) {
      for (const option of group.products) {
        if (option.id === props.option) {
          return option;
        }
      }
    }
    return null;
  };

  return (
    <Modal open={props.option && !!props.option} onClose={props.onClose}>
      <main class="grid w-full max-w-3xl gap-4">
        <p>Vous avez opté pour l'option {option()?.title} !</p>

        <p>
          Pour accéder à cette option, un dépôt de garantie de 140€ non
          encaissé, vous sera demandé. Ce dépôt de garantie peut être encaissé
          totalement ou partiellement en cas de vol, perte ou dégradation du
          matériel qui vous est imputable.
        </p>

        <p>
          Un mandat de prélèvement ainsi qu'un RIB devront nous être transmis.
          Pour compléter votre mandat vous aurez besoin d'une imprimante/scanner
          ou utiliser un logiciel d'édition type{' '}
          <a
            href="https://get.adobe.com/fr/reader/"
            class="underline"
            target="_blank"
            rel="noopener"
          >
            Adobe Acrobat
          </a>
          .
        </p>
      </main>

      <footer class="mx-auto mt-8 grid items-center justify-center gap-4">
        <Button onClick={props.onSubmit} type="button" variant="primary">
          Continuer
        </Button>

        <button type="button" class="underline" onClick={props.onClose}>
          Annuler
        </button>
      </footer>
    </Modal>
  );
}
