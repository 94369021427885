import { extractFormValues } from '@/pages/associate/passenger';
import { useSdk } from '@/stores/sdk';
import { createSignal, ParentProps, Show } from 'solid-js';

import Button from '../button';
import Input from '../input';
import Modal from '../modal';

export default function SearchDuplicate(
  props: ParentProps<{
    values?: Record<string, string>;
    onCancel?: () => void;
    onSuccess?: () => void;
  }>,
) {
  const [sdk] = useSdk();
  const [loading, setLoading] = createSignal(false);
  const [showSuccess, setShowSuccess] = createSignal(false);

  async function handleSubmit(event: Event) {
    event.preventDefault();

    try {
      setLoading(true);

      const form = event.currentTarget as HTMLFormElement;

      const body = extractFormValues(form, [
        'firstName',
        'lastName',
        'birthDate',
        'email',
        'mobileNumber',
        'tbmCustomerNumber',
        'description',
      ]);

      // TODO: Probably handle the response at some point
      const _ = await sdk.zendesk.searchCustomerFailed(body);

      return setShowSuccess(true);
    } catch (error) {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal title="Code client perdu" onClose={props.onCancel} open>
      <Show
        when={!showSuccess()}
        fallback={
          <div class="flex flex-col space-y-4">
            <p>
              Merci, votre demande à bien été soumise aux équipes concernées.
            </p>

            <p>Nous reviendrons vers vous dès que possible</p>

            <Button variant="base" href="/">
              Retour sur la boutique
            </Button>
          </div>
        }
      >
        <p class="text-center">
          Vous avez procédé à une tentative de création de voyageur avec les
          informations suivantes :
        </p>

        <form onSubmit={handleSubmit} class="mt-8 flex flex-col space-y-4">
          <div class="flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <Input
              label="Nom du voyageur"
              name="lastName"
              value={props.values?.lastName}
              id="lastName"
              class="flex-1"
              required
            />

            <Input
              label="Prénom du voyageur"
              name="firstName"
              id="firstName"
              value={props.values?.firstName}
              class="flex-1"
              required
            />
          </div>

          <Input
            label="Date de naissance du voyageur"
            name="birthDate"
            id="birthDate"
            value={props.values?.birthDate}
            required
            type="date"
          />
          <p>
            Pour que nos équipes puissent intervenir, merci de compléter ou
            valider les informations suivantes :
          </p>

          <Input
            label="Adresse e-mail"
            name="email"
            value={props.values?.email}
            id="email"
            required
            readOnly
            type="email"
          />
          <Input
            label="Numéro de mobile"
            name="mobileNumber"
            value={props.values?.mobileNumber}
            readOnly={Boolean(props.values?.mobileNumber)}
            id="mobileNumber"
            required
          />

          <input
            type="hidden"
            name="description"
            id="description"
            value={[
              props.values?.lastName,
              props.values?.firstName,
              props.values?.birthDate,
              props.values?.email,
              props.values?.mobileNumber,
              props.values?.tbmCustomerNumber,
            ].join(', ')}
          />

          <p class="text-center text-xs font-semibold text-gray-400">
            *Champs obligatoires
          </p>

          <Button
            loading={loading()}
            variant="secondary"
            type="submit"
            class="mx-auto"
          >
            Soumettre la demande
          </Button>
        </form>
      </Show>
    </Modal>
  );
}
